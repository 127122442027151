import { useCallback, useState } from 'react'
import { AlgorithmType } from './useCameraConfig';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { dateTimeFormat } from '../utils';

export interface Detection {
  id: number
  camera_id: number
  datetime: Date,
  movie_url: string
  image_url: string
  cropped_image_url: string
  detection: ICarDetection | IFaceDetection | IIntrusionDetection | IPeopleCountDetection
  algorithm: AlgorithmType
  record_type: string
  detection_table: string
}

export interface IFetchDetectionOptions {
  limit?: number
  offset?: number
  startTime?: Date
  endTime?: Date
  locationId?: string
  zoneId?: string
  cameraId?: string | number
  sortBy?: string;
  sortDirection?: string
  algorithm?: string
  subjectType?: string
  recordType?: string
  name?: string
  age?: string
  gender?: string
  subjectNumber?: string
}

interface IDetectionsResponse {
  message: string
  detections: Detection[]
  info: {
    total: number
    limit: number
    offset: number
  }
}

export interface IBaseDetection{
  subject_area?: string
  subject_kana?: string
  subject_number?: string
  subject_user_type?: string
  subject_full_name?: string;
  subject_type?: string;
  unknown: boolean;
  kind?: string;
  direction?: string;
}

export interface ICarDetection extends IBaseDetection {
  id: number,
  camera_category: string
  camera_details: string
  camera_detection_types: string
  camera_id: number
  camera_meta: {}
  camera_name: string
  camera_orientation: number
  camera_position_x: number
  camera_position_y: number
  camera_tags: string
  camera_zone_id: number
  camera_zone_location_id: number
  subject_area: string
  subject_kana: string
  subject_number: string
  subject_user_type: string
  unknown: true
  kind?: string
}

export interface IPeopleCountDetection extends IBaseDetection {
  attribution: {
    current_area: string
    direction: {}
    previous_area: string
    tracking_status: string
  }
  camera_category: string
  camera_details: string
  camera_detection_types: string
  camera_id: number
  camera_meta: {}
  camera_name: string
  camera_orientation: number
  camera_position_x: number
  camera_position_y: number
  camera_scorer_name: string
  camera_tags: string
  camera_zone_id: number
  camera_zone_image: string
  camera_zone_location_id: number
  camera_zone_location_name: string
  camera_zone_location_points: []
  camera_zone_name: string
  camera_zone_points: []
  direction?: string
}

export const isCarDetection = (algorithm: AlgorithmType, kind?: string) => {
  return algorithm === 'NUMBER_PLATE' || algorithm ==='CAR_COUNTING' || (algorithm === 'INTRUSIONS' && kind === 'car')
}

export interface IFaceDetection  extends IBaseDetection {
  id: number
  age: number // 28
  camera_category: string //"2"
  camera_details: string // ""
  camera_detection_types: AlgorithmType  //"NUMBER_PLATE" I think is algorithm please correct if not
  camera_id: number //2
  camera_meta?: {}
  camera_name: string //"Security Gate A"
  camera_orientation: number // 139
  camera_position_x: number // 3008
  camera_position_y: number // 2172
  camera_tags?: string // ""
  camera_zone_id: number //3
  camera_zone_location_id: number
  gender: string //"MALE"
  subject_category: string //"Vegetable"
  subject_description: string // "Alien"
  subject_features: string //"under 9000" LOL
  subject_full_name: string // "Vegeta"
  subject_id: number //3
  subject_photo_url: string // "/path/to/photo/vegeta"
  subject_tags: string // "Alien, Not Cool Guy"
  subject_type: string //"IMPORTANT"
}

export const isFaceDetection = (algorithm: AlgorithmType)=> {
  return (algorithm === 'FACE_IDENTIFICATION');
}


export interface IIntrusionDetection extends IBaseDetection {
  id: number
  attribution:  {}
  camera_category: string // "warehouse"
  camera_details: string //""
  camera_detection_types: string // "NUMBER_PLATE"
  camera_id:  number //2
  camera_meta: {}
  camera_name: string // "Security Gate A"
  camera_orientation: number //139
  camera_position_x: number// 3008
  camera_position_y: number // 2172
  camera_tags: string // ""
  camera_zone_id: string // 3
  direction: string //"IN"
  kind: string // "car"
  detection_table: string // "Intrusion"
  image_url: string // "s3://j.jpeg"
  movie_url: string //"s3://josh.mp4"
  record_id: number | null // null
  record_type: string // "NumberPlate"
}

export const isIntrusion =  (value: any) : value is IIntrusionDetection => {
  if(value === null) {
    return false;
  }

  if(value.kind === 'undefined' || value.kind === null || value.kind === '') {
    return false;
  }

  return true;
}

export const useDetections = () => {

  const [detections, setDetections] = useState<Detection[]>([])
  const [isLoading, setIsLoading] = useState(false);
  const [detectionCount, setDetectionCount] = useState(0);


  const fetchDetections = useCallback(async (options?: IFetchDetectionOptions) => {

    setIsLoading(true);

    const {
      limit = 100,
      offset = 0,
      startTime,
      endTime,
      locationId,
      zoneId,
      cameraId,
      sortBy,
      sortDirection,
      algorithm,
      subjectType,
      recordType,
      name,
      age,
      gender,
      subjectNumber
    } = (options || {});


    const searchString = new URLSearchParams({
      limit: '' + limit,
      offset: '' + offset,
      start_time: format(startTime ? startTime : sub(new Date(), { days: 12 }), dateTimeFormat),
      end_time: format(endTime ? endTime : new Date(), dateTimeFormat),
      ...(cameraId !== undefined ? { camera_id: `${cameraId}` } : {}),
      ...(name !== undefined ? { subject_full_name: `${name}` } : {}),
      ...(age !== undefined ? { age: `${age}` } : {}),
      ...(gender !== undefined ? { gender: `${gender}` } : {}),
      ...(locationId ? { camera_zone_location_id: `${locationId}` } : {}),
      ...(zoneId ? { camera_zone_id: `${zoneId}` } : {}),
      ...(sortBy ? { sort_by: `${sortBy}` } : {}),
      ...(sortDirection ? { sort_direction: `${sortDirection}` } : {}),
      ...(algorithm !== undefined ? { algorithm: `${algorithm}` } : {}),
      ...(subjectType !== undefined ? { subject_type: `${subjectType}` } : {}),
      ...(recordType !== undefined ? { record_type: `${recordType}` } : {}),
      ...(subjectNumber !== undefined ? { subject_number: subjectNumber } : {}),
    });

    const url = '/api/detection?' + searchString.toString();
    try {
      const response = await fetch(url)
      if (response.ok) {
        const {
          detections,
          info: {
            total
          }
        } = await response.json() as IDetectionsResponse

        setDetections(detections);
        setDetectionCount(total);
      } else {
        return false;
      }
      return true;

    } catch (error) {
      console.error('error', error)
      return false;
    } finally {
      setIsLoading(false);
    }

  }, [])

  const deleteDetection = useCallback(async (dectectionId: number): Promise<boolean> => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/detection/${dectectionId}`, { method: 'DELETE' })

      if (response.ok) {
        return true;
      } else {
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      console.error('error', error);
      setIsLoading(false);
      return false;
    }
  }, [])

  return {
    detections,
    isLoading,
    detectionCount,
    actions: {
      fetchDetections,
      deleteDetection
    }
  }
}