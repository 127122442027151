import { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  Content,
  PageHeader,
  ButtonWithLoading,
  Button,
  TextField,
  TextAreaField,
  useTo,
  Spinner,
  useNotification,
  Label,
  RadioButton,
  Input,
  Icon
} from 'scorer-ui-kit';
import styled from 'styled-components';
import { useNumberPlate } from '../hooks/useNumberPlate';
import { NumberPlate } from '../hooks/useNumberPlates';
import { IParams } from '../types';

const Container = styled(Content)`
  overflow: inherit;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-Between;
`;

const PageHeaderLeftContainer = styled.div`
  max-width: 610px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const CancelButton = styled(Button)`
  margin-right: 9px;
`;

const RequiredLabel = styled(Label)`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.79;
  color: #8cbee2;
  margin: -5px 0 0 6px;
`;

const RequiredNoteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  gap: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 470px;
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 33px;
  justify-content: space-between;
  gap: 20px;
`;

const KanaFieldsRow = styled(FieldsRow)`
  margin-top: 2px;
  margin-bottom: 10px;
`;

const DescriptionRow = styled(FieldsRow)`
  margin-top: 10px;
  label {
    width: 100%;
  }
`;

const RadioFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 11px;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 55px;
`;

const FieldLabel = styled(Label)`
  margin-bottom: 2px;
`;

const TagsWrapper = styled.div`
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetectedImageLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 449px;
  height: 282px;
  padding: 5px;
`;

const DetectedImage = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.colors.divider};
`;

const IconContainer = styled.div`
  > div > svg > path {
    fill: #94dbff;
    stroke: none;
  }
`
const RequiredWrapper = styled.div`
  display: flex;
  svg {
    width: 12px;
    margin-left: 4px;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const AddEditNumberPlate: FC = () => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [numberPlateForm, setNumberPlateForm] = useState<NumberPlate>({ ...useLocation()?.state as NumberPlate, category: '', tags: '' });
  const { numberPlate, numberPlateLoading, numberPlateErrorMessage, actions: { fetchNumberPlate, updateNumberPlate, addNumberPlate } } = useNumberPlate();

  const { t } = useTranslation('AddEditNumberPlate');
  const { plateID }: IParams = useParams();

  const to = useTo();
  const { sendNotification } = useNotification();

  const getMessage = useCallback((field: string) => {
    return t(field) + ' ' + t('error.isRequired');
  }, [t]);

  const validateForm = useCallback(() => {
    if (!numberPlateForm?.name) {
      sendNotification({ type: 'error', message: getMessage('name') })
      return false;
    }
    if (!numberPlateForm?.type) {
      sendNotification({ type: 'error', message: getMessage('type') })
      return false;
    }
    if (!numberPlateForm?.area) {
      sendNotification({ type: 'error', message: getMessage('location') })
      return false;
    }
    if (!numberPlateForm?.user_type) {
      sendNotification({ type: 'error', message: getMessage('vehicleClass') })
      return false;
    }
    if (!numberPlateForm?.kana) {
      sendNotification({ type: 'error', message: getMessage('kana') })
      return false;
    }
    if (!numberPlateForm?.number) {
      sendNotification({ type: 'error', message: getMessage('number') })
      return false;
    }

    // Checking valid 4-digit number with/withouth "-".
    const numberPlateRegEx = /(?=[1-9]\d-\d{2}$)(?=^[\d-]{5}$)|(?!0\d*$)(?=^\.*\d+$)(?=^[.\d]{1,4}$)/;
    if (!(numberPlateRegEx.test(numberPlateForm.number))) {
      sendNotification({ type: 'error', message: t('error.pleaseEnterValidNumber') })
      return false;
    }
    return true;
  }, [numberPlateForm, sendNotification, getMessage, t]);

  const onSave = useCallback(async () => {
    if (numberPlateForm === undefined) return;
    if (!validateForm()) return;

    setUpdateLoading(true);

    const payload = { ...numberPlateForm };

    delete payload?.isUnknown;
    delete payload?.image_url;

    const { errorMessage } = await (plateID ? updateNumberPlate(payload) : addNumberPlate(payload));
    if (errorMessage === '') {
      sendNotification({ type: 'success', message: t('success.numberPlateUpdatedSuccessfully') })
      to('/number-plates')();
    } else {
      sendNotification({ type: 'error', message: t('error.' + errorMessage) })
    }
    setUpdateLoading(false);

  }, [numberPlateForm, updateNumberPlate, to, sendNotification, t, addNumberPlate, validateForm, plateID]);

  useEffect(() => {
    numberPlate && setNumberPlateForm(numberPlate);
  }, [numberPlate]);

  useEffect(() => {
    plateID && fetchNumberPlate(parseInt(plateID));
  }, [plateID, fetchNumberPlate]);

  const onChangeHandler = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNumberPlateForm(numberPlateForm ? ({ ...numberPlateForm, [name]: value }) : ({ [name]: value }) as NumberPlate);
  }, [numberPlateForm]);

  const onChangeRadioHandler = useCallback((value) => {
    setNumberPlateForm(numberPlateForm ? ({ ...numberPlateForm, type: value }) : ({ type: value }) as NumberPlate);
  }, [numberPlateForm]);

  useEffect(() => {
    numberPlateErrorMessage && sendNotification({ type: 'error', message: t('error.failedToFetchNumberPlate') });
  }, [numberPlateErrorMessage, sendNotification, t]);

  const handleKeyPress = (event: { key: string; preventDefault: () => void; }) => {
    if (!/^[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Container>
      <PageHeaderWrapper>

        <PageHeaderLeftContainer>
          <PageHeader icon='ViewSettings' title={plateID ? t('editNumberPlate') : t('addNumberPlate')} areaTitle={t('numberPlates')} areaHref='/number-plates' introductionText={t('introText')} />
        </PageHeaderLeftContainer>

        <ButtonsWrapper>
          <CancelButton design='secondary' size='small' onClick={to('/number-plates')}>{t('cancel')}</CancelButton>
          <ButtonWithLoading loading={updateLoading} size='small' onClick={onSave}>{t('save')}</ButtonWithLoading>
        </ButtonsWrapper>

      </PageHeaderWrapper>

      <RequiredNoteContainer>
        <IconContainer>
          <Icon icon='Required' color='primary' size={12} weight='light' />
        </IconContainer>
        <RequiredLabel htmlFor='' labelText={t('denotesARequiredField')} />
      </RequiredNoteContainer>
      {(numberPlateLoading && plateID) || numberPlateErrorMessage !== '' ?
        <Spinner size='large' styling='primary' /> :

        <FormWrapper>
          <FormContainer>
            <InputContainer>
              <RequiredWrapper>
                <FieldLabel htmlFor='' labelText={`${t('referenceName')}`} />
                <IconContainer>
                  <Icon icon='Required' color='primary' size={12} weight='light' />
                </IconContainer>
              </RequiredWrapper>
              <Input
                name='name'
                fieldState='default'
                value={numberPlateForm?.name}
                onChange={onChangeHandler}
              />
            </InputContainer>

            <RadioFieldWrapper>
              <RequiredWrapper>
                <FieldLabel htmlFor='' labelText={`${t('type')}`} />
                <IconContainer>
                  <Icon icon='Required' color='primary' size={12} weight='light' />
                </IconContainer>
              </RequiredWrapper>
              <RadioButtonsWrapper>
                <Label htmlFor='LISTED' labelText={t('listed')} rightAlign>
                  <RadioButton
                    currentChecked={numberPlateForm?.type}
                    id='LISTED'
                    value='LISTED'
                    onChangeCallback={onChangeRadioHandler}
                  />
                </Label>
                <Label htmlFor='IMPORTANT' labelText={t('important')} rightAlign>
                  <RadioButton
                    currentChecked={numberPlateForm?.type}
                    id='IMPORTANT'
                    value='IMPORTANT'
                    onChangeCallback={onChangeRadioHandler}
                  />
                </Label>
              </RadioButtonsWrapper>
            </RadioFieldWrapper>

            <FieldsRow>
              <InputContainer>
                <RequiredWrapper>
                  <FieldLabel htmlFor='' labelText={`${t('location')}`} />
                  <IconContainer>
                    <Icon icon='Required' color='primary' size={12} weight='light' />
                  </IconContainer>
                </RequiredWrapper>
                <Input
                  name='area'
                  fieldState='default'
                  value={numberPlateForm?.area}
                  onChange={onChangeHandler}
                  placeholder='e.g. 品川'
                />
              </InputContainer>
              <InputContainer>
                <RequiredWrapper>
                  <FieldLabel htmlFor='' labelText={`${t('vehicleClass')}`} />
                  <IconContainer>
                    <Icon icon='Required' color='primary' size={12} weight='light' />
                  </IconContainer>
                </RequiredWrapper>
                <Input
                  name='user_type'
                  fieldState='default'
                  value={numberPlateForm?.user_type}
                  onKeyPress={handleKeyPress}
                  onChange={onChangeHandler}
                  placeholder='e.g. 500'
                />
              </InputContainer>
            </FieldsRow>

            <KanaFieldsRow>
              <InputContainer>
                <RequiredWrapper>
                  <FieldLabel htmlFor='' labelText={`${t('kana')}`} />
                  <IconContainer>
                    <Icon icon='Required' color='primary' size={12} weight='light' />
                  </IconContainer>
                </RequiredWrapper>
                <Input
                  name='kana'
                  fieldState='default'
                  value={numberPlateForm?.kana}
                  onChange={onChangeHandler}
                  placeholder='e.g. さ'
                />
              </InputContainer>
              <InputContainer>
                <RequiredWrapper>
                  <FieldLabel htmlFor='' labelText={`${t('number')}`} />
                  <IconContainer>
                    <Icon icon='Required' color='primary' size={12} weight='light' />
                  </IconContainer>
                </RequiredWrapper>
                <Input
                  name='number'
                  fieldState='default'
                  value={numberPlateForm?.number}
                  onChange={onChangeHandler}
                  placeholder='e.g. 46-49'
                  maxLength={5}
                />
              </InputContainer>
            </KanaFieldsRow>


            <TextField
              fieldState='default'
              label={t('category')}
              name='category'
              placeholder='e.g. Sales Team'
              value={numberPlateForm?.category as string}
              onChange={onChangeHandler}
              maxLength={32}
            />

            <TagsWrapper>
              <TextField
                fieldState='default'
                label={t('tags')}
                name='tags'
                placeholder='e.g. Priority, Parking'
                value={numberPlateForm?.tags as string}
                onChange={onChangeHandler}
              />
            </TagsWrapper>

            <DescriptionRow>
              <TextAreaField
                name='description'
                label={t('description')}
                fieldState='default'
                rows={6}
                value={numberPlateForm?.description}
                onChange={onChangeHandler}
              />
            </DescriptionRow>
          </FormContainer>

          {numberPlateForm?.isUnknown && numberPlateForm?.image_url &&
            <ImageContainer>
              <DetectedImageLabel>{t('detectedImageReference')}</DetectedImageLabel>
              <ImageWrapper>
                <DetectedImage alt={t('detectedImageReference')} src={`${numberPlateForm.image_url}`} />
              </ImageWrapper>
            </ImageContainer>}
        </FormWrapper>}

    </Container>
  )
};

export default AddEditNumberPlate;